<template lang="">
	<div class="touristrou">
		<Header class="hide" />
    <Headers class="dispy" />
		<BannerImage rulename="scenicspot_banner" />
		<div class="w">
			<Breadcrumb :crumblist="crumbdata" class="hide" />
			<div class="strip">
				<div class="car">
					旅游路线
				</div>
			</div>
			<OnerowLists :urllist="list" detailurl="/tourisdeta" />
		</div>
		<Footer />
	</div>
</template>

<script>
import Breadcrumb from "@/components/main/Breadcrumb";
import Header from "@/components/main/Header";
import Footer from "@/components/main/Footer";
import OnerowLists from '@/components/main/OnerowLists'
import BannerImage from '@/components/main/BannerImage'
import Headers from "@/components/wap/Headers";
export default {
    components: {
        Breadcrumb,
        Header,
        Footer,
		OnerowLists,
		BannerImage,
		Headers
	},
    data() {
        return {
			list: "/way/tourWay/list",
			crumbdata: [
				{name: '景点与路线',url: ''},
				{name: '旅游路线',url: '/tourisdeta'},
			]
        };
    },
    methods: {
        
    },
  }
</script>
<style lang="scss" scoped>

</style>
